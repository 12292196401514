// 个人信息
import axios from 'axios';
const getDefaultState = () => {
    return {
        access_token: undefined,
        refresh_token: undefined,
    }
}
const state = getDefaultState();
const mutations = {
    getToken(state, res) {
        state.access_token = res['access_token'];
        state.refresh_token = res['refresh_token'];
    },
    resetModuleState(state, res) {
        Object.assign(state, getDefaultState())
    }
};

const actions = {
    resetState({ commit }) {
        commit('resetModuleState');
    },
    refreshToken({ commit , state }) {
        let params = {
            refresh_token: Cookies.get('refresh_token')
        }
         Api.Login.refreshToken(params).then(resData => {
            commit('getToken',{
                access_token: resData.data.access_token,
                refresh_token: Cookies.get('refresh_token')
            });
            Cookies.set('access_token',resData.data.access_token)
        }).catch(error => {
            console.log(error);
        });
             
    }
};

export default {
    state,
    mutations,
    actions
};