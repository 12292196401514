// import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import './assets/fonts/iconfont/iconfont.css'
Vue.use(VueRouter)
import routes from './router.js'
import store from './vuex/store'
import axios from 'axios'
// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload)

const router = new VueRouter({
  mode: 'history',
  routes
})

// import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(Element)

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'; // 引入 utc 插件
dayjs.extend(utc)
Vue.prototype.dayjs = dayjs;

// import Api from './http/index'
// Vue.use(Api)
import Api from './config/api/index'
window.Api = Api

import Common from "./config/utils/common"
window.Common = Common

// 公共地址
Vue.prototype.downloadURL = axios.defaults.baseURL + "/s/fu/front/upload/download?identifier=";

//定义公共组件
import footer from './views/common/footer.vue'
Vue.component('footer-view', footer)
import headers from './views/common/headers.vue'
Vue.component('headers', headers)

//公共组件
import CommonMComs from './common/src'
Vue.use(CommonMComs)

import Cookies from 'js-cookie'
// 配置成全局使用
window.Cookies = Cookies
// Cookies.get = Cookies.getJSON

Vue.config.productionTip = false

// 解决vuex不能存储的问题
// 监听浏览器刷新,将vuex store字符串化存储在 session中
// 在页面初始化时监听是否存在 字符串化的store
// 存在的话 replaceState 更改根节点状态 将session中的字符串解析并赋值页面刷新 存储vuex数据
if (sessionStorage.getItem("store")) {
  store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store"))))
  sessionStorage.removeItem('store');
}
window.addEventListener('beforeunload', () => {
  sessionStorage.setItem("store", JSON.stringify(store.state));
})


Vue.prototype.urlEncode = function objectToQueryString(obj) {
  return Object.keys(obj).map(function (key) {
    return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(obj[key]));
  }).join('&');
};

export default window.VueInstance = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')