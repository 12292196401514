<template>
    <el-dialog title="忘记密码" :visible.sync="visibleForget" modal-append-to-body :append-to-body="true"
        @close="closeDialog" :show-close="true" width="450px" :close-on-click-modal="false">
        <el-form :model="forgetForm" :rules="rules" ref="forgetForm">
            <el-form-item class="phone" prop="phone">
                <el-input v-model="forgetForm.phone" placeholder="请输入手机号">
                    <template slot="prefix">
                        <span class="area-code">+86</span>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input v-model="forgetForm.code" ref="code" placeholder="请输入短信验证码">
                    <template slot="suffix">
                        <el-button class="send-code csp" id="CaptchaId" @click="sendCode" :disabled="sendCodeBtn">
                            {{codeText}}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="forgetForm.password" show-password placeholder="新密码，密码8-16位，须包含大小写字母及数字">
                </el-input>
            </el-form-item>
            <el-form-item prop="confirmPwd">
                <el-input v-model="forgetForm.confirmPwd" show-password placeholder="确认密码">
                </el-input>
            </el-form-item>


        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit">提交</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import captcha from './captcha';
    export default {
        props: {
            visibleForget: {
                type: Boolean,
                default: false
            }
        },
        data() {
            // 验证手机号
            var checkPhone = (rule, value, callback) => {
                var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
                if (!value) {
                    return callback(new Error('请输入手机号'));
                } else if (!phoneRegex.test(value)) {
                    this.sendCodeBtn = true;
                    return callback(new Error('手机号格式异常，请检查并重新输入'));
                } else if (value && phoneRegex.test(value)) {
                    let params = {
                        email_or_phone_num: value
                    }
                    Api.Login.getAccountExists(params).then(resData => {
                        console.log('exists手机号', resData.data.exists);
                        if (!resData.data.exists) {
                            this.sendCodeBtn = true;
                            return callback(new Error('用户不存在'));
                        } else {
                            this.sendCodeBtn = false;
                            return callback();
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    return callback();
                }
            };
            // 验证验证码
            var checkCode = (rule, value, callback) => {
                var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
                if (phoneRegex.test(this.forgetForm.phone) && !value) {
                    return callback(new Error('请填写验证码'));
                } else if (phoneRegex.test(this.forgetForm.phone)) {
                    let params = {
                        phone_num: this.forgetForm.phone,
                        sms_code: value
                    }
                    Api.Login.verifySmsCode(params).then(resData => {
                        console.log('手机验证码是否通过', resData.data.sms_correct);
                        if (resData.data.sms_correct) {
                            return callback();
                        } else {
                            return callback(new Error('短信验证码错误'));
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    return callback();
                }
            };
            // 验证密码
            var checkPwd = (rule, value, callback) => {
                var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/;
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else if (!passwordRegex.test(value)) {
                    return callback(new Error('密码格式错误：密码8-16位，须包含大小写字母及数字'));
                } else {
                    return callback();
                }
            };
            // 验证密码 二次验证
            var checkConfirmPwd = (rule, value, callback) => {
                if (!this.forgetForm.password) {
                    return callback();
                }
                if (!value) {
                    return callback(new Error('请再次输入密码'));
                } else if (value !== this.forgetForm.password) {
                    return callback(new Error('两次输入密码不一致，请检查并重新输入'));
                } else {
                    return callback();
                }
            };
            return {
                forgetForm: {
                    password: '',
                    passwordAgain: '',
                    phone: '',
                    code: '',
                },
                rules: {
                    phone: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPhone
                    }],
                    code: [{
                        required: true,
                        trigger: "blur",
                        validator: checkCode
                    }],
                    password: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPwd
                    }],
                    confirmPwd: [{
                        required: true,
                        trigger: "blur",
                        validator: checkConfirmPwd
                    }]
                },
                codeText: '获取验证码',
                sendCodeBtn: true,
            }
        },
        methods: {
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 关闭注册弹窗
            closeDialog() {
                this.resetForm('forgetForm');
                this.$emit('close', false)
            },
            // 提交
            submit() {
                this.$refs.forgetForm.validate(valid => {
                    if (valid) {
                        let params = {
                            phone_num: this.forgetForm.phone,
                            new_password: this.forgetForm.password,
                            sms_code: this.forgetForm.code
                        }
                        Api.Login.resetPassword(params).then(resData => {
                            console.log('重置密码返回', resData);
                            if (resData.status == 204) {
                                this.$message.success('密码重置成功')
                                this.closeDialog()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        })
                    } else {
                        // this.$message.error('失败')
                        return false
                    }
                })
            },
            // 获取验证码
            async sendCode() {
                let that = this;
                this.sendCodeBtn = true;
                // 进行行为验证
                let res = await captcha.captchaTrigger()
                console.log('callback', res)
                // 成功后发送验证码

                if (res.ret === 0) {
                    let params = {
                        phone_num: that.forgetForm.phone,
                        randstr: res.randstr,
                        ticket: res.ticket
                    }
                    Api.Login.sendSmsCode(params).then(resData => {
                        if (resData.status == 204) {
                            this.$message.success('验证码发送成功');
                            let countdownTimer = 60;
                            this.codeText = countdownTimer + 's后重发';
                            let timer = null;
                            timer = setInterval(() => {
                                countdownTimer--;
                                this.codeText = countdownTimer + 's后重发';
                                this.sendCodeBtn = true;
                                if (countdownTimer <= 0) {
                                    clearInterval(timer);
                                    this.codeText = '获取验证码';
                                    this.sendCodeBtn = false;
                                }
                            }, 1000)
                        }
                    }).catch(error => {
                        console.log(error);
                        this.sendCodeBtn = false;
                    })
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    @primary-color: #3e6eff;
    @text-color: #181818;

    /deep/ .el-dialog {
        border-radius: 24px;
        padding: 45px 39px 35px;

        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__title {
            font-weight: 600;
            line-height: 32px;
            color: @text-color;
        }

        .el-dialog__body {
            padding: 35px 0px 21px;
        }

        .el-form-item {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 11px;
            }
        }

        .el-input {
            font-size: 16px;

            .el-input__clear {
                font-size: 16px;
                height: 110%;
            }

            &.is-active .el-input__inner,
            .el-input__inner:focus {
                border-color: @text-color;
                outline: 0;
                box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.20);
            }

            &.is-active .el-input__inner,
            .el-input__inner:hover {
                border-color: @text-color;
                outline: 0;
            }
        }

        .phone .el-input__inner {
            padding-left: 53px;
        }

        .send-code {
            color: @text-color;
            border: 1px solid #f5f5f5;
            background-color: #f5f5f5;
            vertical-align: -5px;
            padding: 8px 16px;

            &.is-disabled {
                color: #C0C4CC;
            }

            &:active {
                border: 1px solid #e9e4e4;
            }
        }

        .area-code {
            color: @text-color;
            margin-left: 10px;
            line-height: 24px;
            font-size: 16px;
            vertical-align: -5px;
        }

        .el-input__inner {
            border-radius: 8px;
            background-color: #f5f5f5;
            border: 1px solid #fff;
            height: 48px;
            line-height: 24px;
            padding: 12px 16px;
            transition: border-color, box-shadow .2s cubic-bezier(.645, .045, .355, 1);

            /* browsers */
            &::placeholder,
            /* WebKit browsers */
            &::-webkit-input-placeholder,
            /* Mozilla Firefox 19+ */
            &::-moz-placeholder,
            /* Internet Explorer 10+ */
            &:-ms-input-placeholder {
                color: #aaa;
                font-size: 16px;
            }
        }



        .other {
            .el-checkbox {
                color: @text-color;
            }

            .el-checkbox__inner {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
            }

            .el-checkbox__label {
                padding-left: 7px;
                line-height: 24px;
            }

            .to-agreement {
                color: @primary-color;
                font-size: 14px;
                line-height: 24px;
            }
        }

        .el-dialog__footer {
            padding: 0;

            .el-button {
                width: 100%;
                border-radius: 8px;
                border: 0;
                line-height: 1.5;
                font-size: 16px;
                font-weight: 500;
            }

            .el-button--primary {
                background-color: @primary-color;
            }
        }

        .to-login {
            margin-top: 26px;

            .login {
                color: @primary-color;
            }
        }


    }
</style>