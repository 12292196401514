<template>
  <div id="app">
    <headers></headers>
    <router-view></router-view>
    <footer-view></footer-view>
  </div>
</template>
<script>
  export default {
    name: 'app',
    data() {
      return {

      }
    },
    components: {},
    computed: {},
    methods: {

    },
    mounted() {
      
    },
    created() {}
  }
</script>
<style lang="less">
  @import 'less/frontend.less';

  // 阿里字体图标设置
  .icon,
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    height: 100%;
  }
</style>