import Home from './Home'
import Login from './login'


const Api = {
  Home,
  Login
}

// 导出
export default Api
