<template>
    <el-dialog title="欢迎登录观天者" :visible.sync="visibleLogin" :append-to-body="true" @opened="openDialog"
        @close="closeDialog" :show-close="true" width="450px" :destroy-on-close="true" :close-on-click-modal="false">
        <el-form :model="loginForm" :rules="rules" ref="loginForm">
            <el-form-item prop="phone">
                <el-input v-model="loginForm.phone" placeholder="请输入邮箱/手机号"></el-input>
            </el-form-item>
            <el-form-item prop="password" :error="pwdError">
                <el-input v-model="loginForm.password" show-password placeholder="密码8-16位，须包含大小写字母及数字">
                </el-input>
            </el-form-item>
        </el-form>
        <div class="other flex-align-between">
            <el-checkbox v-model="remember" @change="changeRemember">记住密码</el-checkbox>
            <span class="forget csp" @click="visibleForget = true">忘记密码</span>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="login" :loading="isLoginLoading">{{isLoginLoading?'登录中':'登录'}}</el-button>
            <p class="to-rigister">没有账号？去<span class="rigister csp" @click="toRegister">注册</span></p>
        </div>
        <forget :visibleForget="visibleForget" @close="visibleForget = false"></forget>
    </el-dialog>
</template>

<script>
    import forget from './forget.vue';
    import captcha from './captcha';
    import {
        mapState,
        mapMutations
    } from "vuex";
    export default {
        components: {
            forget
        },
        props: {
            // visibleLogin: {
            //     type: Boolean,
            //     default: false
            // }
        },
        data() {
            // 验证邮箱
            var checkEmail = (rule, value, callback) => {
                var emailRegex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
                var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
                if (!value) {
                    return callback(new Error('请输入邮箱或手机号'));
                }

                if (emailRegex.test(value) || phoneRegex.test(value)) {
                    let params = {
                        email_or_phone_num: value
                    }
                    Api.Login.getAccountExists(params).then(resData => {
                        // console.log('exists', resData.data.exists);
                        if (!resData.data.exists) {
                            return callback(new Error('用户不存在'));
                        } else {
                            return callback();
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    return callback(new Error('邮箱/手机号格式异常，请检查并重新输入'));
                }
            }
            // 验证密码
            var checkPwd = (rule, value, callback) => {
                var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/;
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else if (!passwordRegex.test(value)) {
                    return callback(new Error('密码格式错误：密码8-16位，须包含大小写字母及数字'));
                } else {
                    return callback();
                }
            };
            return {
                visibleForget: false,
                loginForm: {
                    phone: '',
                    password: '',
                },
                rules: {
                    phone: [{
                        required: true,
                        trigger: "blur",
                        validator: checkEmail
                    }],
                    password: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPwd
                    }],
                },
                remember: false,
                pwdError: undefined,
                isLoginLoading: false,
            }
        },
        computed: {
            ...mapState({
                accessToken: state => state.user.access_token,
                refreshToken: state => state.user.refresh_token,
                visibleLogin: state => state.login.visibleLogin,
            }),
        },
        mounted() {},
        watch: {},
        methods: {
            ...mapMutations({
                setToken: 'getToken',
                setLogin: 'visibleLoginDialog',
            }),
            // 重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 打开登录框回调
            openDialog() {
                window.addEventListener('keyup', this.enterLogin, true)
            },
            // 关闭登录弹窗回调
            closeDialog() {
                window.removeEventListener('keyup', this.enterLogin, true);
                this.resetForm('loginForm')
                this.pwdError = ''
                this.$emit('close', false)
            },
            changeRemember(val) {
                // console.log(val)
            },
            // 登录
            login() {
                this.isLoginLoading = true;
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        this.getLoginRetryChance().then(times => {
                            // console.log('次数', times);
                            if (times > 0) {
                                this.submit();
                            } else {
                                this.$message.error('账户已被锁定1天，暂时无法登录，请点击“忘记密码”进行密码重置');
                                this.isLoginLoading = false;
                            }
                        })
                        // this.$message.success('登录')
                    } else {
                        this.isLoginLoading = false;
                        return false
                    }
                })
            },
            // 查询登录剩余次数
            async getLoginRetryChance() {
                let params = {
                    email_or_phone_num: this.loginForm.phone
                }
                let resData = await Api.Login.getLoginRetryChance(params)
                // console.log('登录剩余次数', resData.data.remaining_attempts);
                return new Promise((resolve, reject) => {
                    resolve(resData.data.times);
                })
            },
            // 提交
            async submit() {
                let that = this;
                // 进行行为验证
                let res = await captcha.captchaTrigger()
                // console.log('callback', res)
                if (res.ret === 0) {
                    let params = {
                        email_or_phone_num: that.loginForm.phone,
                        password: that.loginForm.password,
                        remember_me: that.remember,
                        randstr: res.randstr,
                        ticket: res.ticket
                    }
                    Api.Login.login(params).then(resData => {
                        this.isLoginLoading = false;
                        // this.$message.success(resData.data.access_token);
                        this.setToken(resData.data);
                        Cookies.set('access_token', resData.data.access_token)
                        Cookies.set('refresh_token', resData.data.refresh_token)
                        this.$message.success('登陆成功');
                        this.$emit('success');
                        this.closeDialog();
                    }).catch(error => {
                        this.getLoginRetryChance().then(times => {
                            // console.log('次数', times);
                            this.pwdError = `密码错误，今日还可以重试${times}次`
                            this.isLoginLoading = false;
                        })
                    })
                } else {
                    this.isLoginLoading = false;
                    this.$message.error(res.errorMessage)
                }
            },
            enterLogin() {
                let key = !window.event ? e.keyCode : window.event.keyCode;
                if (key === 13) {
                    this.login();
                }
            },
            // 忘记密码
            forgetPwd() {},
            // 跳转注册
            toRegister() {
                this.$emit('toRegister', {
                    openLogin: false,
                    openRegister: true,
                })
            },

        },
        destroyed() {
            window.removeEventListener('keyup', this.enterLogin, true);
        },
    }
</script>

<style lang="less" scoped>
    @primary-color: #3e6eff;
    @text-color: #181818;

    /deep/ .el-dialog {
        border-radius: 24px;
        padding: 45px 39px 35px;

        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__title {
            font-weight: 600;
            line-height: 32px;
            color: @text-color;
        }

        .el-dialog__body {
            padding: 35px 0px 21px;
        }

        .el-form-item {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 11px;
            }
        }

        .el-input {
            font-size: 16px;

            .el-input__clear {
                font-size: 16px;
                height: 110%;
            }

            &.is-active .el-input__inner,
            .el-input__inner:focus {
                border-color: @text-color;
                outline: 0;
                box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.20);
            }

            &.is-active .el-input__inner,
            .el-input__inner:hover {
                border-color: @text-color;
                outline: 0;
            }
        }

        .el-input__inner {
            border-radius: 8px;
            background-color: #f5f5f5;
            border: 1px solid #fff;
            height: 48px;
            line-height: 24px;
            padding: 12px 16px;
            transition: border-color, box-shadow .2s cubic-bezier(.645, .045, .355, 1);

            /* browsers */
            &::placeholder,
            /* WebKit browsers */
            &::-webkit-input-placeholder,
            /* Mozilla Firefox 19+ */
            &::-moz-placeholder,
            /* Internet Explorer 10+ */
            &:-ms-input-placeholder {
                color: #aaa;
                font-size: 16px;
            }
        }

        .el-form-item.is-error .el-input__inner,
        .el-form-item.is-error .el-input__inner:focus,
        .el-form-item.is-error .el-textarea__inner,
        .el-form-item.is-error .el-textarea__inner:focus,
        .el-message-box__input input.invalid,
        .el-message-box__input input.invalid:focus {
            border: 1px solid #DF0629;
            background-color: #FEE6EA;
            box-shadow: 0px 0px 0px 2px rgba(223, 6, 41, 0.20);
        }

        .el-form-item__error {
            color: #DF0629;

        }

        .other {
            .el-checkbox {
                color: @text-color;
            }

            .el-checkbox__inner {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
            }

            .el-checkbox__label {
                padding-left: 7px;
                line-height: 24px;
            }

            .forget {
                color: @primary-color;
                font-size: 14px;
                line-height: 24px;
            }
        }

        .el-dialog__footer {
            padding: 0;

            .el-button {
                width: 100%;
                border-radius: 8px;
                border: 0;
                line-height: 1.5;
                font-size: 16px;
                font-weight: 500;
            }

            .el-button--primary {
                background-color: @primary-color;
            }
        }

        .to-rigister {
            margin-top: 26px;

            .rigister {
                color: @primary-color;
            }
        }


    }
</style>