import axios from 'axios';
import DoApi from './utils/DoApi';
import store from "@/vuex/store"
import Cookies from 'js-cookie';
axios.defaults.timeout = 10000;
console.log(process.env.NODE_ENV)
axios.defaults.baseURL = process.env.VUE_APP_API_URL

//http request 请求拦截器
axios.interceptors.request.use(
  config => {
    let token = Cookies.get('access_token')
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': 'application/json; charset=utf-8'
    }
    // const token = store.state.user.token || window.sessionStorage.getItem("token");
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//http response 响应拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  async (error) => {
    // console.log(error.response);
    if (error.response.status) {
      let refreshToken = Cookies.get('refresh_token')
      switch (error.response.status) {
        // 401:accesstoken 过期或者无效
        case 401:
          // 接口是refresh的情况
          if (error.response.config.url.indexOf('/auth/refresh') != -1 && refreshToken) {
            VueInstance.$confirm('登录信息已过期，请重新登录', '系统提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // 重新登录 
              Cookies.remove('access_token')
              Cookies.remove('refresh_token')
              store.dispatch('resetState')
              store.state.login.visibleLogin = true
            }).catch(() => {
              // store.dispatch('resetState')
            })
            return;
          }
          try {
            const resData = await Api.Login.refreshToken({
              refresh_token: Cookies.get('refresh_token')
            });
            // console.log('resData',resData.data)
            store.commit('getToken', {
              access_token: resData.data.access_token,
              refresh_token: Cookies.get('refresh_token')
            });
            Cookies.set('access_token', resData.data.access_token)
            error.response.config.data = JSON.parse(error.response.config.data);
            return axios(error.config);
          } catch (error) {
            console.log(error)
          }

          default:
            return Promise.reject(error);
      }
    }
    // return Promise.reject(error)
  }
)
// api装饰器
function send(target, name, descriptor, callback) {
  descriptor.value = function () {
    return callback(arguments[0]);
  };
  return descriptor;
}

// 添加 路径
function url(url) {
  return function (target, name, descriptor) {
    descriptor.url = url;
  }
}

function get(target, name, descriptor) {
  return send(target, name, descriptor, function (args, url) {
    return axios.get(descriptor.url + '?' + DoApi.jsonUrlFormat(args));
  });
}

function post(target, name, descriptor) {
  return send(target, name, descriptor, function (args) {
    // return axios.post(descriptor.url + '?' + DoApi.jsonUrlFormat({}), DoApi.doJson(args));
    return axios.post(descriptor.url + '?' + DoApi.jsonUrlFormat({}), DoApi.doJson(args));
  });
}

function fetch(target, name, descriptor) {
  return send(target, name, descriptor, function (args) {
    return axios.get(descriptor.url + args);
  });
}

export {
  get,
  url,
  post,
  fetch,
}