// 任务
const state = {
  visibleLogin: false,
  visibleRegister: false,
}

const mutations = {
  visibleLoginDialog(state, data) {
    state.visibleLogin = data
  },
  visibleRegisterDialog(state, data) {
    state.visibleRegister = data
  }
}

export default {
  state,
  mutations
}