<template>
    <el-dialog title="欢迎加入观天者" :visible.sync="visibleRegister" :append-to-body="true" @open="openDialog"
        @close="closeDialog" :show-close="true" width="450px" :destroy-on-close="true" :close-on-click-modal="false">
        <el-form :model="registerForm" :rules="rules" ref="registerForm">
            <el-form-item prop="email">
                <el-input v-model="registerForm.email" id="email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="registerForm.password" id="pwd" show-password placeholder="密码8-16位，须包含大小写字母及数字">
                </el-input>
            </el-form-item>
            <el-form-item prop="confirmPwd">
                <el-input v-model="registerForm.confirmPwd" id="confirmPwd" show-password placeholder="确认密码">
                </el-input>
            </el-form-item>
            <el-form-item prop="phone">
                <el-input v-model="registerForm.phone" class="phone" id="phone" placeholder="请输入手机号">
                    <template slot="prefix">
                        <span class="area-code">+86</span>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input v-model="registerForm.code" id="code" placeholder="请输入短信验证码">
                    <template slot="suffix">
                        <el-button class="send-code csp" id="CaptchaId" :disabled="sendCodeBtn" @click="sendCode">
                            {{codeText}}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="agree" class="other" :show-message="false">
                <el-checkbox v-model="registerForm.agree">我已阅读并同意
                </el-checkbox>
                <span class="to-agreement csp" @click.stop="toAgreement">《观天者用户服务协议》</span>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="register" :loading="isRegisterLoading">{{isRegisterLoading?'注册中':'注册'}}
            </el-button>
            <p class="to-login">已有账号？去<span class="login csp" @click="toLogin">登录</span></p>
        </div>
    </el-dialog>
</template>

<script>
    import captcha from './captcha';
    import {
        mapState,
        mapMutations
    } from "vuex";
    export default {
        props: {
            // visibleRegister: {
            //     type: Boolean,
            //     default: false
            // }
        },
        data() {
            // 验证邮箱格式
            var checkEmail = (rule, value, callback) => {
                var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!value) {
                    return callback(new Error('请输入邮箱'));
                } else if (!emailRegex.test(value)) {
                    return callback(new Error('请输入正确的邮箱格式'));
                } else if (value && emailRegex.test(value)) {
                    let params = {
                        email_or_phone_num: value
                    }
                    Api.Login.getAccountExists(params).then(resData => {
                        console.log('exists邮箱', resData.data.exists);
                        if (resData.data.exists) {
                            return callback(new Error('该邮箱已被注册'));
                        } else {
                            return callback();
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    return callback();
                }
            };
            // 验证密码
            var checkPwd = (rule, value, callback) => {
                var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$/;
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else if (!passwordRegex.test(value)) {
                    return callback(new Error('密码格式错误：密码8-16位，须包含大小写字母及数字'));
                } else {
                    return callback();
                }
            };
            // 验证密码 二次验证
            var checkConfirmPwd = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请再次输入密码'));
                } else if (value !== this.registerForm.password) {
                    return callback(new Error('两次输入密码不一致，请检查并重新输入'));
                } else {
                    return callback();
                }
            };
            // 验证手机号
            var checkPhone = (rule, value, callback) => {
                var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
                if (!value) {
                    return callback(new Error('请输入手机号'));
                } else if (!phoneRegex.test(value)) {
                    this.sendCodeBtn = true;
                    return callback(new Error('手机号格式异常，请检查并重新输入'));
                } else if (value && phoneRegex.test(value)) {
                    let params = {
                        email_or_phone_num: value
                    }
                    Api.Login.getAccountExists(params).then(resData => {
                        // console.log('exists手机号', resData.data.exists);
                        if (resData.data.exists) {
                            return callback(new Error('该手机号已被注册'));
                        } else {
                            this.sendCodeBtn = false;
                            return callback();
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    return callback();
                }
            };
            // 验证验证码
            var checkCode = (rule, value, callback) => {
                var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
                if (phoneRegex.test(this.registerForm.phone) && !value) {
                    return callback(new Error('请填写验证码'));
                } else if (phoneRegex.test(this.registerForm.phone)) {
                    let params = {
                        phone_num: this.registerForm.phone,
                        sms_code: value
                    }
                    Api.Login.verifySmsCode(params).then(resData => {
                        console.log('手机验证码是否通过', resData.data.sms_correct);
                        if (resData.data.sms_correct) {
                            return callback();
                        } else {
                            return callback(new Error('短信验证码错误'));
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    return callback();
                }
            };
            // 验证协议
            var checkAgree = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请勾选同意服务协议'));
                } else {
                    return callback();
                }
            };
            return {
                registerForm: {
                    email: '',
                    password: '',
                    confirmPwd: '',
                    phone: '',
                    code: '',
                    agree: false,
                },
                rules: {
                    email: [{
                        required: true,
                        trigger: "blur",
                        validator: checkEmail
                    }],
                    password: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPwd
                    }],
                    confirmPwd: [{
                        required: true,
                        trigger: "blur",
                        validator: checkConfirmPwd
                    }],
                    phone: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPhone
                    }],
                    code: [{
                        required: true,
                        trigger: "blur",
                        validator: checkCode
                    }],
                    agree: [{
                        required: true,
                        trigger: "change",
                        validator: checkAgree
                    }],
                },
                sendCodeBtn: true,
                codeText: '发送验证码',
                smsCode: undefined,
                CaptchaAppId: '197951980',
                AppSecretKey: 'MPxHapyXBHTL514Wv9QFEEI6d',
                isRegisterLoading: false,
            }
        },
        computed: {
            ...mapState({
                visibleRegister: state => state.login.visibleRegister,
            }),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                setRegister: 'visibleRegisterDialog',
            }),
            // 重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$refs[formName].clearValidate();
            },
            // 打开登录框回调
            openDialog() {
                window.addEventListener('keyup', this.enterRegister, true)
            },
            // 关闭注册弹窗
            closeDialog() {
                window.removeEventListener('keyup', this.enterRegister, true);
                this.resetForm('registerForm');
                this.$emit('close', false)
            },
            enterRegister() {
                let key = !window.event ? e.keyCode : window.event.keyCode;
                if (key === 13) {
                    this.register();
                }
            },
            // 注册
            register() {
                let that = this;
                that.isRegisterLoading = true;
                this.$refs.registerForm.validate(valid => {
                    if (valid) {
                        let params = {
                            "email": that.registerForm.email,
                            "phone_num": that.registerForm.phone,
                            "password": that.registerForm.password,
                            "sms_code": that.registerForm.code, // 手机验证码
                        }
                        Api.Login.register(params).then(resData => {
                            // console.log('注册返回', resData.data);
                            that.isRegisterLoading = false;
                            if (resData.status == 201) {
                                this.$message.success('注册成功')
                                this.closeDialog();
                            } else {
                                this.$message.error(resData.data.message)
                            }
                        }).catch(error => {
                            that.isRegisterLoading = false;
                            console.log(error)
                        })
                    } else {
                        that.isRegisterLoading = false;
                        return false
                    }
                })
            },
            // 跳转协议
            toAgreement() {
                window.open('/agreement', '_blank')
            },
            // 跳转登录
            toLogin() {
                this.$emit('toLogin', {
                    openLogin: true,
                    openRegister: false,
                })
            }, // 发送验证码
            async sendCode() {
                let that = this;
                this.sendCodeBtn = true;
                // let res = captcha.captchaTrigger();
                // 进行行为验证
                let res = await captcha.captchaTrigger()
                // console.log('callback', res)
                // 成功后发送验证码
                if (res.ret === 0) {
                    let params = {
                        phone_num: that.registerForm.phone,
                        randstr: res.randstr,
                        ticket: res.ticket
                    }
                    Api.Login.sendSmsCode(params).then(resData => {
                        if (resData.status == 204) {
                            this.$message.success('验证码发送成功');
                            let countdownTimer = 60;
                            let timer = null;
                            this.codeText = countdownTimer + 's后重发';
                            timer = setInterval(() => {
                                countdownTimer--;
                                this.codeText = countdownTimer + 's后重发';
                                if (countdownTimer <= 0) {
                                    clearInterval(timer);
                                    this.codeText = '发送验证码';
                                    this.sendCodeBtn = false;
                                }
                            }, 1000)
                        }
                    }).catch(error => {
                        console.log(error);
                        this.sendCodeBtn = false;
                    })
                }
            },
        },
        destroyed() {
            window.removeEventListener('keyup', this.enterRegister, true);
        },
    }
</script>

<style lang="less" scoped>
    @primary-color: #3e6eff;
    @text-color: #181818;

    /deep/ .el-dialog {
        border-radius: 24px;
        padding: 45px 39px 35px;

        .el-dialog__header {
            padding: 0;
        }

        .el-dialog__title {
            font-weight: 600;
            line-height: 32px;
            color: @text-color;
        }

        .el-dialog__body {
            padding: 35px 0px 1px;
        }

        .el-form-item {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 11px;
            }
        }

        .el-input {
            font-size: 16px;

            .el-input__clear {
                font-size: 16px;
                height: 110%;
            }

            &.is-active .el-input__inner,
            .el-input__inner:focus {
                border-color: @text-color;
                outline: 0;
                box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.20);
            }

            &.is-active .el-input__inner,
            .el-input__inner:hover {
                border-color: @text-color;
                outline: 0;
            }
        }

        .phone .el-input__inner {
            padding-left: 53px;
        }


        .send-code {
            color: @text-color;
            border: 1px solid #f5f5f5;
            background-color: #f5f5f5;
            vertical-align: -5px;
            padding: 8px 16px;

            &.is-disabled {
                color: #C0C4CC;
            }

            &:active {
                border: 1px solid #e9e4e4;
            }
        }

        .area-code {
            color: @text-color;
            margin-left: 10px;
            line-height: 24px;
            font-size: 16px;
            vertical-align: -5px;
        }

        .el-input__inner {
            border-radius: 8px;
            background-color: #f5f5f5;
            border: 1px solid #fff;
            height: 48px;
            line-height: 24px;
            padding: 12px 16px;
            transition: border-color, box-shadow .2s cubic-bezier(.645, .045, .355, 1);

            /* browsers */
            &::placeholder,
            /* WebKit browsers */
            &::-webkit-input-placeholder,
            /* Mozilla Firefox 19+ */
            &::-moz-placeholder,
            /* Internet Explorer 10+ */
            &:-ms-input-placeholder {
                color: #aaa;
                font-size: 16px;
            }
        }

        .el-form-item.is-error .el-input__inner,
        .el-form-item.is-error .el-input__inner:focus,
        .el-form-item.is-error .el-textarea__inner,
        .el-form-item.is-error .el-textarea__inner:focus,
        .el-message-box__input input.invalid,
        .el-message-box__input input.invalid:focus {
            border: 1px solid #DF0629;
            background-color: #FEE6EA;
            box-shadow: 0px 0px 0px 2px rgba(223, 6, 41, 0.20);
        }

        .el-form-item__error {
            color: #DF0629;

        }


        .other {
            .el-checkbox {
                color: @text-color;
            }

            .el-checkbox__inner {
                border: 1px solid #d9d9d9;
                border-radius: 4px;
            }

            .el-checkbox__label {
                padding-left: 7px;
                line-height: 24px;
            }

            .to-agreement {
                color: @primary-color;
                font-size: 14px;
                line-height: 24px;
            }

            &.is-error {
                .el-checkbox__inner {
                    border: 1px solid #DF0629;
                }
            }
        }

        .el-dialog__footer {
            padding: 0;

            .el-button {
                width: 100%;
                border-radius: 8px;
                border: 0;
                line-height: 1.5;
                font-size: 16px;
                font-weight: 500;
            }

            .el-button--primary {
                background-color: @primary-color;
            }
        }

        .to-login {
            margin-top: 26px;

            .login {
                color: @primary-color;
            }
        }


    }
</style>